import startCase from 'lodash/startCase'
import { renderMarkdown, stripMarkdown } from '@scm/ui-core'
import { Seo } from '@scm/content-domain'
import { ContentJSON } from '@scm/ui-module-content'

import { TopicSlugContentType, TopicSlugData } from '~/types.ts'
import { MediaCenterPageType } from '~/constants/media-center'

export type MetaElement =
  | {
      rel: string
      href: string
      tagName?: string
    }
  | { name: string; content: string }
  | { title: string }
  | { 'script:ld+json': any }
  | { property: string; content: string }

type MetaArr = (MetaElement | undefined)[]

export type SeoReturnType = {
  canonicalUrl: string
  siteName?: string
  imageUrl: string
  keywords?: string
  noArchive?: boolean
} & Seo

type Pagination = {
  totalCount: number
  currentPage: number
}

export type PaginationPageData = {
  entryTitle: string
  pagination?: Pagination
  isAuthor?: boolean
  isChildrenPage?: boolean
}

export type PaginationContentPageData = {
  siteName: string
  type: 'content-type' | 'content-format'
  contentName: string
  pagination?: Pagination
  canonicalUrl: string
  imageUrl: string
}

type BaseExtraMeta = {
  schema?: any[]
  imagesToPreload?: Record<string, string>[]
  robots?: string
  pageType?: string
}

export type CreateBaseMeta = {
  seo: {
    canonicalUrl: string
    siteName?: string
    imageUrl: string
    keywords?: string
  }
  contentType?: TopicSlugContentType
  title: string
  description: string
} & BaseExtraMeta

type BaseAdvancedMeta = {
  seo: SeoReturnType
  contentType?: TopicSlugContentType
} & BaseExtraMeta

type AdvancedMeta = {
  isLivePreview?: boolean
} & BaseAdvancedMeta

type AdvancedPaginatedMeta = {
  paginationPageData: PaginationPageData
  seriesPageType?: string
} & BaseAdvancedMeta

export enum PageType {
  ARTICLE = 'article',
  WEBSITE = 'website',
}

export const getRobotsTagContentValue = (...rules: string[]) => {
  return [...rules, 'max-image-preview:large'].filter(v => v).join(', ')
}

const Prop = (property: string, content = '') =>
  content ? { property, content } : undefined
const PropName = (name: string, content = '') =>
  content ? { name, content } : undefined
const LinkProp = (rel = '', href = '') =>
  rel && href ? { rel, href, tagName: 'link' } : undefined
const Schema = (content: any) =>
  content ? { 'script:ld+json': content } : undefined
const ImageProp = ({ rel, href, as }: any) =>
  rel && href ? { rel, href, as, tagName: 'link' } : undefined

const createBaseMeta = ({
  seo,
  schema,
  imagesToPreload,
  contentType,
  title,
  description,
}: CreateBaseMeta) => {
  const metaElements: MetaArr = [
    { title },
    PropName('description', description),
    Prop('og:title', title),
    Prop('og:description', description),
    Prop('og:url', seo?.canonicalUrl),
    Prop('og:type', contentType || PageType.WEBSITE),
    Prop('og:image', seo?.imageUrl),
    Prop('og:site_name', seo.siteName),
    Prop('oc:keywords', seo.keywords),
    LinkProp('canonical', seo?.canonicalUrl),
  ]
  if (Array.isArray(schema)) {
    metaElements.push(...schema.map(Schema))
  }
  if (Array.isArray(imagesToPreload)) {
    metaElements.push(...imagesToPreload.map(ImageProp))
  }
  return metaElements.filter(e => e) as MetaElement[]
}

export const createCustomMeta = (
  tags: MetaElement[],
  { schema, imagesToPreload, robots }: BaseExtraMeta,
) => {
  const metaElements: MetaArr = [...tags, PropName('robots', robots)]
  if (Array.isArray(schema)) {
    metaElements.push(...schema.map(Schema))
  }
  if (Array.isArray(imagesToPreload)) {
    metaElements.push(...imagesToPreload.map(ImageProp))
  }
  return metaElements.filter(e => e) as MetaElement[]
}

export const createAllContentPaginationMeta = ({
  pagination,
  contentName,
  siteName,
  type,
  pageType,
  canonicalUrl,
  imageUrl,
  imagesToPreload,
  schema,
  robots,
}: PaginationContentPageData & BaseExtraMeta) => {
  const isContentType = type === 'content-type'
  const titleStart = createPaginatedMetaTitleStart({
    pageType: pageType,
    contentName: contentName,
    isContentType: isContentType,
  })
  const titlePagintation = `page ${pagination?.currentPage ?? 1} of ${
    pagination?.totalCount ?? 1
  }`
  const title = `${titleStart} | ${titlePagintation} | ${siteName}`
  const description = isContentType
    ? `Explore the latest multimedia resources brought to you by the editors of ${siteName}`
    : `Explore the latest articles brought to you by the editors of ${siteName}`
  const metaElements: MetaArr = [
    PropName('robots', robots || 'noindex'),
    ...createBaseMeta({
      seo: {
        siteName,
        canonicalUrl,
        imageUrl,
      },
      schema,
      imagesToPreload,
      title,
      description,
    }),
  ]
  return metaElements.filter(e => e) as MetaElement[]
}

export const createPaginatedMetaTitleStart = ({
  pageType,
  contentName,
  isContentType,
}: any): string => {
  switch (pageType) {
    case MediaCenterPageType.ALL_SERIES: {
      return 'Series collection of podcasts, videos, and webinars'
    }
    case MediaCenterPageType.ALL_COMPANIES: {
      return 'Recent digital contributions from other companies'
    }
    case MediaCenterPageType.INDIVIDUAL_COMPANY: {
      return `Recent digital contributions from ${contentName}`
    }
    case MediaCenterPageType.INDIVIDUAL_COLLECTION:
    case MediaCenterPageType.INDIVIDUAL_SERIES: {
      return `${contentName} collection`
    }
    default: {
      return `Recent ${contentName}${isContentType ? '' : ' articles'}`
    }
  }
}

export const createPaginatedMetaTags = ({
  seo,
  paginationPageData,
  contentType,
  seriesPageType,
  imagesToPreload,
  schema,
  robots,
}: AdvancedPaginatedMeta) => {
  if (!seo) return []
  const {
    entryTitle,
    pagination,
    isAuthor = false,
    isChildrenPage = false,
  } = paginationPageData

  const title = `${entryTitle} ${
    seriesPageType ? `${seriesPageType} collection` : 'recent news'
  } | page ${pagination?.currentPage} of ${pagination?.totalCount} | ${
    seo.siteName && entryTitle.includes(seo.siteName) ? '' : seo.siteName
  }`
  const description = isChildrenPage
    ? `Explore the latest news on this event brought to you by the editors of ${seo.siteName}`
    : `Explore the latest news and expert commentary` +
      (!isAuthor ? ` on ${entryTitle} ` : ' ') +
      `brought to you by the editors of ${seo.siteName}`

  const metaElements: MetaArr = [
    {
      name: 'robots',
      content: robots || getRobotsTagContentValue(seo.noIndex ? 'noindex' : ''),
    },
    ...createBaseMeta({
      seo,
      schema,
      contentType,
      imagesToPreload,
      title,
      description,
    }),
  ]
  return metaElements.filter(e => e) as MetaElement[]
}

export const createAllContentMeta = ({
  contentName,
  siteName,
  canonicalUrl,
  imageUrl,
  imagesToPreload,
  schema,
  robots,
}: PaginationContentPageData & BaseExtraMeta) => {
  const metaElements: MetaArr = [
    PropName('robots', robots || 'noindex'),
    ...createBaseMeta({
      seo: {
        siteName,
        canonicalUrl,
        imageUrl,
      },
      schema,
      imagesToPreload,
      title: `${startCase(contentName)} | ${siteName}`,
      description: `Explore the latest ${contentName} brought to you by the editors of ${siteName}`,
    }),
  ]
  return metaElements as MetaElement[]
}

export const createMetaTags = ({
  seo,
  schema,
  contentType,
  isLivePreview,
  imagesToPreload,
  robots,
}: AdvancedMeta) => {
  if (!seo) return []
  const description = renderMarkdown(seo.metaDescription)
  const title = stripMarkdown(seo.metaTitle)
  const metaElements: MetaArr = [
    {
      name: 'robots',
      content:
        robots ||
        getRobotsTagContentValue(
          isLivePreview || seo.noIndex ? 'noindex' : '',
          seo.noArchive ? 'noarchive' : '',
        ),
    },
    ...createBaseMeta({
      seo,
      schema,
      contentType,
      imagesToPreload,
      title,
      description,
    }),
    Prop('twitter:card', 'summary'),
  ]
  return metaElements.filter(e => e) as MetaElement[]
}

export const getSeoLinks = (seo: SeoReturnType) => [
  { tagName: 'link', rel: 'canonical', href: seo.canonicalUrl },
]

export const getFallbackMetaDescription = (data: TopicSlugData) => {
  const template = data.template as any
  const firstParagraph = (template?.bodyJson as ContentJSON)?.find(
    i => i.type === 'paragraph',
  )
  // @ts-ignore
  const firstTextBlock = firstParagraph?.content?.find(i => i.type === 'text')
  const firstSentence = firstTextBlock?.text?.split('.')?.[0]
  return template?.summary || `${firstSentence}.`
}
