export const MORE_CONTENT_ITEM_PAGE_LIMIT = 24
export const PAGE_TITLE_SERIES = 'Media Center All Series'
export const SEO_NO_INDEX = true
export const PAGE_TITLE_COMPANIES = 'Media Center All Companies'
export enum MediaCenterPageType {
  ALL_SERIES = 'media-center-all-series',
  ALL_COMPANIES = 'media-center-all-companies',
  INDIVIDUAL_COMPANY = 'media-center-individual-company',
  INDIVIDUAL_COLLECTION = 'media-center-individual-collection',
  INDIVIDUAL_SERIES = 'media-center-individual-series',
}
